import React from 'react';
import { Button } from './Button';
import './Pricing.css';
import { FaFire } from 'react-icons/fa';
import { BsXDiamondFill } from 'react-icons/bs';
import { GiCrystalize } from 'react-icons/gi';
import { IconContext } from 'react-icons/lib';
import { Link } from 'react-router-dom';

function Pricing() {
  return (
    <IconContext.Provider value={{ color: '#fff', size: 64 }}>
     <h1 className='pricing__heading'>Pricing</h1>
     <div className='pricing__container'>
        <Link to='/sign-up' className='pricing__container-card'>
         </Link>
      </div>
    </IconContext.Provider>
  );
}
export default Pricing;
