import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { MdFingerprint } from 'react-icons/md';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import Logo from "../assets/images/fratino.png";


function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  },[]);
  
   /* window.addEventListener('resize', showButton);
    return {
      window.removeEventListener('resize', showButton)
    }
  }, []);*/


  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <nav className='navbar'>
          <div className='navbar-container container'>
            <Link to='/'  className='navbar-logo' onClick={closeMobileMenu}>
              <img src={Logo} className='navbar-icon' />
              &nbsp;&nbsp;Home
            </Link>
            <div className='menu-icon' onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
              <li className='nav-item'> <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                  Informazioni
                </Link>
              </li>
              <li className='nav-item'> <Link to='/wall' className='nav-links'
                 onClick={closeMobileMenu}>
                  Wall
                </Link>
              </li>
              <li className='nav-item'> <Link to='/arte' className='nav-links'
                  onClick={closeMobileMenu}>
                  Arte
                </Link>
              </li>
              <li className='nav-item'> <Link to='/news' className='nav-links'
                  onClick={closeMobileMenu}>
                  News
                </Link>
              </li>
              <li className='nav-item'> <Link to='/video' className='nav-links'
                  onClick={closeMobileMenu}>
                  Video
                </Link>
              </li>
              <li className='nav-item'> <Link  to='/sign-in' className="nav-links" 
                 onClick={closeMobileMenu}>
                 Login
                </Link>
              </li>
            
              <li className='nav-btn'>
                {button ? (
                  <Link to='/sign-up' className='btn-link'>
                    <Button buttonStyle='btn--outline'>SIGN UP</Button>
                  </Link>
                ) : (
                  <Link to='/sign-up' className='btn-link'>
                    <Button
                      buttonStyle='btn--outline'
                      buttonSize='btn--mobile'
                      onClick={closeMobileMenu}
                    >
                      SIGN UP
                    </Button>
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
