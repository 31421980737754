export const SliderCarousel = [
  {
    image:
    '../fratina4.jpg'
  },
  
  {
    image:
    '../fratino6.jpg'
  },

  {
    image:
    '../Fratinosceriffo.jpg'
  }
];


