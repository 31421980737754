export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'ISCRIVITI',
  headline: 'Iscriviti per ricevere gli aggiornamenti e le notizie',
  description:
    'A breve per gli iscritti potrai inserire i tuoi report o foto sul Parco della Palude',
  buttonLabel: 'Sign Up',
  imgStart: '',
  img: 'images/svg-6.svg',
  alt: 'Credit Card'
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: '100% SECURE',
  headline: 'Stay protected 24/7 anywhere anytime',
  description:
    'We have you covered no matter where you are located. Over 140 locations worldwide to ensure you have access anytime',
  buttonLabel: 'Learn More',
  imgStart: '',
  img: 'images/svg-5.svg',
  alt: 'Vault'
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'EASY SETUP',
  headline: 'Immediato processo per Iscrizione',
  description:
    " ",
  buttonLabel: 'START',
  imgStart: 'start',
  img: 'images/svg-7.svg',
  alt: 'Vault'
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'DATA ANALYTICS',
  headline: 'Ogni dato è custodito con il massimo della riservatezza',
  description:
    'Puoi consultare notizie sulla palude in ogni momento',
  buttonLabel: 'Iscriviti oggi',
  imgStart: 'start',
  img: 'images/svg-8.svg',
  alt: 'Vault'
};
