import React, { useState } from 'react';
import "./styles.css";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';

const ImageSlider = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const length = slides.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }


  return (
    //<div className="header">
      <div className="header__box">
        <FaArrowAltCircleLeft className="left-arrow" onClick={prevSlide} />
        <FaArrowAltCircleRight className="right-arrow" onClick={nextSlide} />
        {slides.map((slide, index) => {
          return (
            <div className={index === current ? "slide active2" : "slide2"}>
              {index === current ? (
                <img src={slide.image} className="imageslider" alt=""/>
              ) : null}
              </div>
          );
        })}
       </div>
    //</div>
  );
};

export default ImageSlider;
