import React, { useState, useEffect } from "react";
import axios from 'axios';
import './components/HeroSection.css';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { isMobile } from "react-device-detect";


const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    color: 'black',
  },
  paper: {
    textAlign: 'center',
    padding: 10,
    //boxShadow: shadows[1],
    boxShadow: '0 3px 4px 2px rgba(150, 152, 150, .4)',
    color: 'black',
  },

});


const Video = () => {
   const classes = useStyles();
   const [todos, setTodo] = useState();

   useEffect(() => {
        axios.get(`./videoflavia.json`).then(res => {
            const responseTodos = res.data;
            setTodo(responseTodos);
        })
        .catch(err => {
            console.log('Error : ' + err);
        });
    }, []);

    return (
    
     <div>
        <div className='heading2'> Video di Torre Flavia </div> 
        {todos && 
           todos.map((todo) => {
             const {title, link, preview, note} = todo;
             return (
                <div className={classes.root}>
                {!isMobile ? (
                <Grid container spacing={3}>
                  <Grid item xs>
                    <Paper></Paper>
                   </Grid>
                  <Grid item xs={6}>
                   <br></br><br></br>
                    <Paper className={classes.paper}  >
                    {/*<div className="line"></div>*/}
                    <p>
                    <heading3>{title} </heading3>
                    <h5><a href={link} target="_blank"><br></br>{!preview ? link :<img src={preview}></img>} </a></h5>
                    <body> {note} </body><br></br>
                    </p>
                    <br></br>
                   </Paper>
                  </Grid>
                  <Grid item xs>
                    <Paper></Paper>
                  </Grid>
                </Grid>
                ) : (
                  <Grid item xs={12}>  
                   <br></br><br></br>
                    <Paper className={classes.paper}  >
                    <p>
                    <heading3>{title} </heading3>
                    <h5><a href={link} target="_blank"><br></br>{!preview ? link :<img src={preview}></img>} </a></h5>
                    <body> {note} </body><br></br>
                    </p>
                    <br></br>
                    </Paper>
                  </Grid>
                )}
           
              </div>
               
             );
             })}    
    </div>
    );
};

export default Video;