import React, { useState, useEffect } from "react";
import axios from 'axios';
import './components/HeroSection.css';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { shadows } from '@material-ui/system';
import { isMobile } from "react-device-detect";


/* base64 conversioni stringa per json
import uuidv4 from 'uuid/v4';
const nonce = new Buffer(uuidv4()).toString('base64');

It is very important that you use UUID version 4, as it generates an unpredictable string. You then apply this nonce to the CSP header. A CSP header might look like this with the nonce applied:

header('Content-Security-Policy')
  .set(`default-src 'self'; style-src: 'self' 'nonce-${nonce}';`);

<style
  id="jss-server-side"
  nonce={nonce}
  dangerouslySetInnerHTML={{ __html: sheets.toString() }}
/>
 background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',da rosa scuro ad arancione
 boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
   
 */
/*
const classes = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    boxShadow: theme.shadows[3],
    },
  paper: {
    padding: theme.spacing(12),
    textAlign: 'center',
    boxShadow: theme.shadows[3],
    color: theme.palette.text.secondary,
  },
}));

style={{ backgroundColor: 'white', padding: 10, boxShadow: 3, color: 'red', textAlign: 'center' }}
*/

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    color: 'black',
  },
  paper: {
    textAlign: 'center',
    padding: 10,
    //boxShadow: shadows[1],
    boxShadow: '0 3px 4px 2px rgba(150, 152, 150, .4)',
    color: 'black',
  },

});



const Arte = () => {
    const classes = useStyles();
    const [todos, setTodo] = useState();

   useEffect(() => {
        axios.get(`arte.json`).then(res => {
            const responseTodos = res.data;
            setTodo(responseTodos);
        })
        .catch(err => {
            console.log('Error : ' + err);
        });
    }, []);

    return (
            <div>
               <div className='heading2'> Articoli della Sezione Arte </div> 
               {todos && 
                  todos.map((todo) => {
                    const {title, link, preview} = todo;
                    return (
                        <div className={classes.root} >
                       {!isMobile ? (
                        <Grid container spacing={3}>
                          <Grid item xs>
                            <Paper ></Paper>
                           </Grid>
                          <Grid item xs={6}>
                            <br></br><br></br>
                            <Paper className={classes.paper}  >
                            {/*<div className="line"></div>*/}
                            <p>
                            <heading3>{title} </heading3>
                            <h5><a href={link} target="_blank" rel="noopener noreferrer"><br></br>{!preview ? link :<img src={preview} alt=""></img>} </a></h5>
                            </p>
                            <br></br>
                           </Paper>
                          </Grid>
                          <Grid item xs>
                            <Paper></Paper>
                          </Grid>
                        </Grid>
                       ) : (
                        <Grid item xs={12}>
                        <br></br><br></br>
                        <Paper className={classes.paper}  >
                        {/*<div className="line"></div>*/}
                        <p>
                        <heading3>{title} </heading3>
                        <h5><a href={link} target="_blank" rel="noopener noreferrer"><br></br>{!preview ? link :<img src={preview} alt=""></img>} </a></h5>
                        </p>
                        <br></br>
                       </Paper>
                      </Grid>
                    
                    )}
                    </div>

                    );
                    })}    
           </div>
           );
       };
       
       export default Arte;
