import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

function CarouselComponent({slides}) 
{
    if (!Array.isArray(slides) || slides.length <= 0) {
        return null;
    }   
   
    const length = slides.length;
 
    return (
        <div class="carousel-wrapper">
           <Carousel infiniteLoop useKeyboardArrows >
                {slides.map((slide, index) => {
                return (
                    <div> <img src={"../"+slide.img} /></div>
                );
                })}
            </Carousel>
        </div>
    );
}

export default CarouselComponent;

