export const SliderData = [
  {
    image:
    '../slide_1r.jpg'
  },
  
  {
    image:
    '../slide_2r.jpg'
  }

  
];
