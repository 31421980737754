import React from 'react';
import HeroSection from './HeroSection';
import campodimare from "../assets/images/campomare1.png";
import { FaRegCommentAlt } from 'react-icons/fa';
import { AiOutlineLike } from 'react-icons/ai';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './datawall';
import './wall.css';

function wall() {
    const divStyle = {left: '40%'};

  return (
    <>
   
   <div className='container'>
     <div className="wall" >
     <div className="line"></div>
     <b>FabioMax</b><br></br>
     <socialtime>Yesterday 08:00 AM</socialtime> 
     <br></br><br></br>Foto fatte oggi nella Palude Nord dalla parte di Campo di Mare, era piena di vita peccato che non avevo lo zoom per le foto.<br></br><br></br>
     <div className='wall__container'>
     <img src={campodimare}/>  
     </div>
     <br></br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <AiOutlineLike size="1em" ></AiOutlineLike><b> Mi Piace </b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <FaRegCommentAlt size="1em" ></FaRegCommentAlt> <b>Commento</b>    
     <div className="line"></div>
     <br></br><br></br>
     </div>
     </div>
     <HeroSection {...homeObjThree} />
     </>
  );
}

export default wall;

