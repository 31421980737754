export const SliderDataMobile = [
    {
      image:
      '../slide_1mobile.jpg'
    },
    
    {
      image:
      '../slide_2mobile.jpg'
    }
      
  ];
  