import React, { useState, useEffect } from "react";
import axios from 'axios';
import './components/HeroSection.css';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import useWindowSize from "./useWindowSize";
import { isMobile } from "react-device-detect";
import CarouselComponent from "./components/carousel.component";


//      <CarouselComponent />
//import 'bootstrap/dist/css/bootstrap.min.css';
//import { Carousel } from 'react-bootstrap';



const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    color: 'black',
  },
  paper: {
    textAlign: 'center',
    padding: 10,
    //boxShadow: shadows[1],
    boxShadow: '0 3px 4px 2px rgba(150, 152, 150, .3)',
    color: 'black',
  },

});


/*let theArray = []
useEffect(() => {
        axios
            .get(`/api/data`)
            .then(res => {
                const newItem = {
                  id: res.data.id,
                  name: res.data.name,
                };
                theArray.push(newItem);
             })
    }, [])
*/


const News = () => {
     /*const { width } = useWindowSize();*/
   const classes = useStyles();
   const [todos, setTodo] = useState();
   let theArray = [];

   useEffect(() => {
        axios.get(`./news.json`).then(res => {
            const responseTodos = res.data;
            setTodo(responseTodos);
        })
        .catch(err => {
            console.log('Error : ' + err);
        });
    }, []);

    return (
    
     <div>
     { /*     <Carousel>
         <Carousel.item>
         <img src="../Fratinosceriffo.jpg"  alt="first slide"/>
         </Carousel.item>
         <Carousel.item>
         <img src="../fratina4.jpg"  alt="2 slide"/>
         </Carousel.item>
       </Carousel>*/}
        <div className='heading2'> <h2> L'Angolo di Corrado </h2> </div> 
        {todos && 
           todos.map((itemm) => {
             const {title, testo, link, preview, note, img, v_array} = itemm;
             /*const data =[{"name":"test1"},{"name":"test2"}];
             const listItems = data.map((d) => <li key={d.name}>{d.name}</li>);
             theArray=itemm.v_array;
             const data =itemm.v_array;
             const listItems = data.map((d) => <li key={d.teamId}>{d.teamId}</li>);
             const listItems = theArray.map((d) => <li key={d.teamId}>{d.status}</li>);*/
             return (
                <div className={classes.root}>
                {!isMobile ? (
                <Grid container spacing={3}>
                  <Grid item xs>
                    <Paper ></Paper>
                   </Grid>
                  <Grid item xs={6}>
                   <br></br><br></br>
                    <Paper className={classes.paper}  >
                    {/*<div className="line"></div>*/}
                    <p>
                    <heading2>{title} </heading2>
                    <body>{testo}</body>
                    <h5><a href={link} target="_blank"><br></br>{!preview ? link :<img src={preview}></img>} </a></h5>
                    <h5> <br></br>{!img? null :<img src={img}></img>} </h5>
                    <CarouselComponent slides={v_array}/>
                    <br></br>
                    </p>
                   </Paper>
                  </Grid>
                  <Grid item xs>
                    <Paper></Paper>
                  </Grid>
                </Grid>
                ) : (
                  <Grid item xs={12}>
                     <br></br><br></br>
                    <Paper className={classes.paper}  >
                    <p>
                    <heading2>{title} </heading2>
                    <body>{testo}</body>
                    <h5><a href={link} target="_blank"><br></br>{!preview ? link :<img src={preview}></img>} </a></h5>
                    <h5> <br></br>{!img? null :<img src={img}></img>} </h5>
                    <CarouselComponent slides={v_array}/>
                    </p>
                    <br></br>
                    </Paper>
                  </Grid>
                )}
           
              </div>
               
             );
             })}    
    </div>
    );
};

export default News;